<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex">
        <v-autocomplete
          :value="invoice.invoiceNumber"
          :items="availableInvoiceNumbers"
          :label="availableInvoiceNumbers.length > 1 ? 'Dostępne numery dokumentu' : 'Nr dokumentu'"
          placeholder="Wybierz nr dokumentu"
          outlined
          dense
          :disabled="availableInvoiceNumbers.length < 2"
          @input="[emitUpdate('invoice.invoiceNumber', $event), emitUpdate('preview.invoiceNumber', $event)]"
        />
        <v-btn
          class="ml-2"
          icon
          medium
          color="primary"
          target="_blank"
          @click="$emit('refreshAvailableInvoiceNumbers')"
        >
          <v-icon
            medium
            class="rotate"
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="6">
      <v-menu
        v-model="showSaleDatePicker"
        v-bind="attributes.menu"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(invoice.saleDate)"
            :rules="showSaleDatePicker ? [] : [rules.required]"
            label="Data sprzedaży"
            placeholder="Wybierz datę sprzedaży"
            v-bind="{ ...attrs, ...attributes.textField }"
            v-on="on"
          >
            <template #prepend-inner>
              <Icon
                name="date"
                size="small"
                class="mr-2"
              />
            </template>
            <template #append>
              <SwitchDayButtons
                :value="invoice.saleDate"
                @input="emitUpdate('invoice.saleDate', $event)"
              />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :value="invoice.saleDate"
          v-bind="attributes.datePicker"
          :rules="showSaleDatePicker ? [] : [rules.required]"
          @input="[emitUpdate('invoice.saleDate', $event), showSaleDatePicker = false]"
        />
      </v-menu>
    </v-col>

    <v-col cols="6">
      <v-menu
        v-model="showIssueDatePicker"
        v-bind="attributes.menu"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(invoice.issueDate)"
            :rules="showIssueDatePicker ? [] : [rules.required]"
            label="Data wystawienia"
            placeholder="Wybierz datę wystawienia"
            v-bind="{ ...attrs, ...attributes.textField }"
            v-on="on"
          >
            <template #prepend-inner>
              <Icon
                name="date"
                size="small"
                class="mr-2"
              />
            </template>
            <template #append>
              <SwitchDayButtons
                :value="invoice.issueDate"
                @input="emitUpdate('invoice.issueDate', $event)"
              />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :value="invoice.issueDate"
          v-bind="attributes.datePicker"
          :rules="showIssueDatePicker ? [] : [rules.required]"
          @input="[emitUpdate('invoice.issueDate', $event),showIssueDatePicker = false]"
        />
      </v-menu>
    </v-col>

    <v-col cols="6">
      <v-menu
        v-model="showDueDatePicker"
        v-bind="attributes.menu"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="paymentDueDaysString"
            :rules="showDueDatePicker ? [] : [rules.required]"
            label="Termin płatności"
            placeholder="Wybierz termin płatności"
            v-bind="{ ...attrs, ...attributes.textField }"
            v-on="on"
          >
            <template #prepend-inner>
              <Icon
                name="date"
                size="small"
                class="mr-2"
              />
            </template>
            <template #append>
              <SwitchDayButtons
                :value="invoice.paymentDueDate"
                @input="emitUpdate('invoice.paymentDueDate', $event)"
              />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :value="invoice.paymentDueDate"
          v-bind="attributes.datePicker"
          :rules="showDueDatePicker ? [] : [rules.required]"
          :min="paymentMinDate"
          @input="[emitUpdate('invoice.paymentDueDate', $event),showDueDatePicker = false]"
        />
      </v-menu>
    </v-col>

    <v-col
      v-if="isIndividualClient"
      cols="6"
    >
      <v-autocomplete
        :value="invoice.settlementType"
        :items="individualSettlementTypes"
        label="Typ dokumentu"
        placeholder="Zmień typ dokumentu klienta indywidualnego"
        outlined
        dense
        :disabled="isGeneratingFromInvoice"
        @input="emitUpdate('invoice.settlementType', $event)"
      />
    </v-col>

    <v-col
      v-if="isCustomServiceInvoice"
      cols="12"
    >
      <v-autocomplete
        :value="invoice.paymentType"
        :items="paymentTypes"
        outlined
        label="Typ płatności"
        placeholder="Wybierz typ platności"
        item-text="type"
        item-value="type"
        append-icon="mdi-chevron-down"
        dense
        @input="emitUpdate('invoice.paymentType', $event)"
      />
    </v-col>

    <v-col cols="12">
      <ForwardDocument
        :contact-emails="invoice.contactEmails"
        type="invoice"
        @update="emitUpdate('invoice.contactEmails', $event)"
      />
    </v-col>

    <v-col
      cols="12"
      class="mt-3"
    >
      <v-textarea
        :value="invoice.notes"
        label="Uwagi"
        placeholder="Uwagi dotyczące dokumentu"
        outlined
        hide-details
        dense
        rows="2"
        @input="emitUpdate('invoice.notes', $event)"
      />
    </v-col>
    <v-col>
      <v-checkbox
        :value="invoice.attachUnpaidInvoices"
        label="Załącz informację o zaległościach"
        hide-details
        class="mt-0"
        @change="emitUpdate('invoice.attachUnpaidInvoices', $event)"
      />
      <v-checkbox
        :value="invoice.withDebrisInfo"
        label="Pokaż informacje o odpadach w zestawieniu"
        hide-details
        class="mt-0"
        @change="emitUpdate('invoice.withDebrisInfo', $event)"
      />
      <v-checkbox
        :value="invoice.withInvoiceReport"
        label="Pokaż informacje o kursach w zestawieniu"
        hide-details
        class="mt-0"
        @change="emitUpdate('invoice.withInvoiceReport', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import ForwardDocument from '../../../components/Forms/Partials/ForwardDocument'
import attributes from '../../../const/datePickerAttrributes'
import rules from '../../../utils/validators'
import {
  dateStringFormat,
} from '../../../utils'
import { DateTime, Interval } from 'luxon'
import { mapState } from 'vuex'

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    isIndividualClient: {
      type: Boolean,
      defaul: false,
    },
    isGeneratingFromInvoice: {
      type: Boolean,
      defaul: false,
    },
    isCustomServiceInvoice: {
      type: Boolean,
      defaul: false,
    },
    availableInvoiceNumbers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ForwardDocument,
  },
  data: () => ({
    attributes,
    rules,
    showSaleDatePicker: false,
    showIssueDatePicker: false,
    showDueDatePicker: false,
  }),
  computed: {
    ...mapState({
      baseItem: state => state.layout.dialog.item,
      orders: state => state.orders.items,
      selectedItems: state => state.orders.selectedItems,
      individualSettlementTypes: state =>
        state.core.filters.individualSettlementTypes,
      paymentTypes: state => state.core.filters.paymentTypes,
    }),
    invoiceOrders() {
      return this.orders.filter(order => this.selectedItems.includes(order.id)) || []
    },
    paymentDueDaysString() {
      const { paymentDueDate, issueDate } = this.invoice
      const date = this.formatDate(paymentDueDate)
      const intervalLength = new Interval({
        start: DateTime.fromISO(issueDate),
        end: DateTime.fromISO(paymentDueDate)
      }).length('days')
      const suffix = intervalLength === 1 ? 'dzień' : 'dni'
      return `${date} - ${intervalLength} ${suffix}`
    },
    paymentMinDate() {
      return this.invoice?.issueDate || DateTime.utc().toISODate()
    },
  },
  watch: {
    'invoice.issueDate': function (newDate, previousDate) {
      const issueDate = DateTime.fromISO(newDate)
      const paymentDate = DateTime.fromISO(this.invoice.paymentDueDate)
      if (paymentDate < issueDate) {
        this.emitUpdate('invoice.paymentDueDate', newDate)
      }
      this.emitUpdate('invoice.saleDate', newDate)
      this.setPaymentDueDate()

      const [newDateYear, newDateMonth] = newDate?.split('-').map(string => parseInt(string))
      const [previousDateYear, previousDateMonth] = previousDate?.split('-').map(string => parseInt(string))

      if (newDateYear !== previousDateYear || newDateMonth !== previousDateMonth) {
        this.$emit('resetInvoiceNumber')
      }
    },
    'invoice.settlementType': function (newVal, oldVal) {
      if (newVal === 'Paragon') {
        this.emitUpdate('invoice.invoiceNumber', this.invoice.initialInvoiceNumber)
        this.emitUpdate('preview.invoiceNumber', this.invoice.initialInvoiceNumber)
      }
      if (['Paragon', 'Faktura imienna'].includes(newVal) && newVal !== oldVal) {
        this.$emit('resetInvoiceNumber')
      }
    }
  },
  mounted() {
    this.setPaymentDueDate()
  },
  methods: {
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    formatDate(date) {
      return date ? dateStringFormat(date) : ''
    },
    setPaymentDueDate() {
      const { paymentDueDays, clientPaymentDueDays } = this.invoiceOrders.length ? this.invoiceOrders[0] : {}

      let daysOffset
      if (this.invoiceOrders.length) {
        const samePaymentDueDays = this.invoiceOrders.every(order => order.paymentDueDays === paymentDueDays)
        daysOffset = samePaymentDueDays && paymentDueDays ? paymentDueDays : clientPaymentDueDays || 0
      } else {
        const { paymentType } = this.baseItem?.payment || this.baseItem || {}
        const { paymentDueDays } = this.baseItem?.payment || this.baseItem || {}
        daysOffset = paymentType === 'Przelew' && paymentDueDays ? paymentDueDays : 0
      }
      const dueDate = DateTime.fromISO(this.invoice.issueDate)
        .plus({ day: daysOffset })
        .toISODate()
      this.emitUpdate('invoice.paymentDueDate', dueDate)
    },
  }
}
</script>
